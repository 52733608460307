import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ReactKeycloakProvider as KeycloakProvider } from "@react-keycloak/web";
import { QueryClientProvider } from "@tanstack/react-query";
import { InMemoryProvider, OpenFeature, OpenFeatureProvider } from "@openfeature/react-sdk";

import keycloak, { getInitOptions } from "src/services/keycloak";
import { ProfileProvider } from "src/providers/profile-provider";
import { TokenProvider } from "src/providers/token-provider";
import { AnalyticsProvider } from "src/providers/analytics-provider";

import { Routes } from "./Routes";
import { isStaging } from "./constants";
import { flagConfig } from "./featureFlags";
import { queryClient } from "./queryClient";

interface LocationState {
  from: {
    pathname: string;
    search: string;
    hash: string;
  };
}

OpenFeature.setProvider(new InMemoryProvider(flagConfig));
const openFeatureClient = OpenFeature.getClient();

const App = () => {
  const location = useLocation<LocationState | undefined>();
  const responseMode = queryString.parse(location.search).response_mode?.toString();
  const initOptions = useMemo(() => getInitOptions(location.pathname, responseMode), [location.pathname, responseMode]);

  useEffect(() => {
    OpenFeature.setContext({
      isStaging: isStaging === true,
      isDevelopment: process.env.NODE_ENV === "development",
      isTest: process.env.NODE_ENV === "test",
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error("Error setting OpenFeature context", error);
    });
  }, []);

  return (
    <OpenFeatureProvider client={openFeatureClient}>
      <KeycloakProvider authClient={keycloak} initOptions={initOptions}>
        <TokenProvider>
          <AnalyticsProvider>
            <QueryClientProvider client={queryClient}>
              <ProfileProvider>
                <Routes />
              </ProfileProvider>
            </QueryClientProvider>
          </AnalyticsProvider>
        </TokenProvider>
      </KeycloakProvider>
    </OpenFeatureProvider>
  );
};

export type { LocationState };
export default App;
